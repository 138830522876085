import GlobalLayout from "components/page/GlobalLayout";
import WebinarPageV2 from "components/webinar/WebinarPageV2";
import { navigate } from "gatsby";
import React, { useEffect } from "react";
import Helmet from "react-helmet";

export default function Webinar() {
  useEffect(() => {
    navigate("/webinars/intro-to-next-gen-security/");
  }, []);
  return (
    <GlobalLayout>
      <Helmet>
        <title>
          Rhombus - Enterprise Video Security & Surveillance Webinars
        </title>
        <meta
          name="description"
          content="No DVR/NVRs required. Learn about modern cloud security cameras and environmental sensors in the next live webinar."
        />
      </Helmet>
      <WebinarPageV2 title="Next-Generation Cloud Video Security" />
    </GlobalLayout>
  );
}
